import { useTranslation } from 'react-i18next';
import FlagNameEnum from '~/base/enums/flagNameEnum';
import FlagTypeEnum from '~/base/enums/flagTypeEnum';
import SwitchNameEnum from '~/base/enums/switchNameEnum';
import FlagCheckedChildren from '../../FlagCheckedChildren';
import HeaderNoticeBar from '../HeaderNoticeBar';

interface HeaderBannersProps {
  shouldDisplayTaxBanner: boolean;
  shouldDisplayFlaggedUserRateChangeBanner: boolean;
  shouldDisplayCancelledUserRateChangeBanner: boolean;
}

function HeaderBanners({
  shouldDisplayFlaggedUserRateChangeBanner = false,
  shouldDisplayCancelledUserRateChangeBanner = false,
  shouldDisplayTaxBanner = false,
}: HeaderBannersProps): JSX.Element {
  const { t } = useTranslation(['translation']);

  const banners = [
    {
      name: FlagNameEnum.SHOW_NOTICE_BANNER,
      flagType: FlagTypeEnum.FLAG,
      color: '#606bf8',
      fontColor: '#fff',
      buttonColor: '#fff',
      fontSize: '1.125rem',
      message: t('notice.holiday.notice-text'),
      buttonLabel: t('notice.holiday.notice-button'),
      buttonLink: t('notice.holiday.notice-link'),
      enabled: true,
    },
    {
      name: SwitchNameEnum.SHOW_RATE_CHANGE_BANNERS,
      flagType: FlagTypeEnum.SWITCH,
      color: '#7fc832',
      fontColor: '#000',
      buttonColor: '#ffd71c',
      fontSize: '1rem',
      message: t('notice.rate-banner.notice-text'),
      buttonLabel: t('notice.rate-banner.notice-button'),
      buttonLink: t('notice.rate-banner.notice-link'),
      enabled: shouldDisplayFlaggedUserRateChangeBanner,
    },
    {
      key: `${SwitchNameEnum.SHOW_RATE_CHANGE_BANNERS}_cancelled`,
      name: SwitchNameEnum.SHOW_RATE_CHANGE_BANNERS,
      flagType: FlagTypeEnum.SWITCH,
      color: '#7fc832',
      fontColor: '#000',
      buttonColor: '#ffd71c',
      fontSize: '1rem',
      message: t('notice.cancelled-banner.notice-text'),
      buttonLabel: t('notice.cancelled-banner.notice-button'),
      buttonLink: t('notice.cancelled-banner.notice-link'),
      enabled: shouldDisplayCancelledUserRateChangeBanner,
    },
  ];

  return (
    <>
      {banners
        .filter((x) => x.enabled)
        .map(
          ({
            key,
            name,
            color,
            fontColor,
            buttonColor,
            fontSize,
            message,
            buttonLabel,
            buttonLink,
            flagType,
          }) => (
            <FlagCheckedChildren
              key={key || name}
              name={name}
              type={flagType}
              // eslint-disable-next-line react/jsx-no-useless-fragment
              alternative={<></>}
              hideLoadingIndicator
            >
              <HeaderNoticeBar
                color={color}
                fontColor={fontColor}
                buttonColor={buttonColor}
                fontSize={fontSize}
                message={message}
                hasButton
                buttonLabel={buttonLabel}
                buttonLink={buttonLink}
              />
            </FlagCheckedChildren>
          ),
        )}

      {shouldDisplayTaxBanner && (
        <HeaderNoticeBar
          color="#F2F2F2"
          buttonColor="#fff"
          fontColor="#000"
          fontSize="0.875rem"
          message={t('notice.tax-banner.notice-text')}
          hasButton
          buttonLabel={t('notice.tax-banner.notice-button')}
          buttonLink={t('notice.tax-banner.notice-link')}
          target="_self"
        />
      )}
    </>
  );
}

export default HeaderBanners;
